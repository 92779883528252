import { useAppSelector } from "../../../app/hooks.ts";
import { useTranslation } from "react-i18next";
import { PRAYER_LIST } from "../../../constants/static_data.constants.ts";
import "./PrayerTimes.scss";
import { useNavigate } from "react-router-dom";
import { Language } from "../../../enums/Language.ts";
import Alert from "../../common/Alert/alertLocal.jsx";
import ROUTES_CONSTANTS from "../../../constants/routes.constants.ts";
import ArrowLeft from "../../../assets/logos/ArrowLeftTrans.svg";
import ArrowRight from "../../../assets/logos/ArrowRightTrans.svg";

const PrayerTimes = () => {
  const prayerTimes = useAppSelector((state) => state.prayerTime);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const prayerTimesRoute = ROUTES_CONSTANTS.prayerTimesPage.routeWithSlash;

  // Fonction next prayer
  const getNextPrayer = () => {
    const now = new Date();
    const today = now.toISOString().split("T")[0];

    const times = PRAYER_LIST.map((prayer) => {
      const prayerTimeString = prayerTimes.times[prayer];
      const prayerTime = new Date(`${today}T${prayerTimeString}:00`);
      return { name: prayer, time: prayerTime.getTime() };
    });

    const upcomingPrayer = times.find((prayer) => prayer.time > now.getTime());
    return upcomingPrayer ? upcomingPrayer.name : PRAYER_LIST[0];
  };

  const nextPrayer = prayerTimes?.times ? getNextPrayer() : null;

  return (
    <div className="prayer-times flex justify-between mb-2">
      <div className="prayer-date">
        <div className="flex items-center">
          <h1>{t("prayer.PRAYER_TIMES")}</h1>
          <img
            src={i18n.language === Language.AR ? ArrowLeft : ArrowRight}
            alt="Navigate to prayer times"
            className="h-8 w-8 text-greenCalendar cursor-pointer ml-3"
            style={{
              width: "2.4em",
              height: "2.4em",
              marginBottom: "-0.2em",
              marginLeft: i18n.language === Language.AR ? "0" : "0.75em",
              marginRight: i18n.language === Language.AR ? "0.75em" : "0",
            }}
            onClick={() => {
              navigate(prayerTimesRoute);
            }}
          />
        </div>

        <span style={{ fontSize: "0.9em" }}>
          {prayerTimes?.date &&
            `${new Intl.DateTimeFormat(`${i18n.language}-TN-u-ca-islamic`, {
              day: "numeric",
              month: "long",
              weekday: "long",
              year: "numeric",
            }).format(new Date())} - ${new Intl.DateTimeFormat(
              `${i18n.language}-TN-u-ca`,
              {
                day: "numeric",
                month: "long",
                year: "numeric",
              }
            ).format(new Date())}`}
        </span>
      </div>

      {!prayerTimes?.date ? (
        <Alert from="home" />
      ) : (
        <div className="display-times">
          <div className="flex items-end">
            {PRAYER_LIST.map((prayer) => (
              <div key={prayer} className="flex flex-col items-center">
                <span className="prayer-name">{t("prayer." + prayer)}</span>
                <span
                  className={`time ${
                    prayer === nextPrayer ? "next-prayer" : ""
                  }`}
                  style={{
                    borderRadius:
                      prayer === "Fajr"
                        ? i18n.language === Language.AR
                          ? "0px 10px 10px 0px"
                          : "10px 0px 0px 10px"
                        : prayer === "Isha"
                        ? i18n.language === Language.AR
                          ? "10px 0px 0px 10px"
                          : "0px 10px 10px 0px"
                        : "none",
                  }}
                >
                  {prayerTimes.times[prayer]}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default PrayerTimes;
