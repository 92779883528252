import { useAppDispatch, useAppSelector } from "../../app/hooks.ts";
import { useEffect, useState } from "react";
import { fetchPrayerTimes } from "../../features/prayerTime/prayerTimeThunk.ts";
import PrayerTimes from "../../components/specific/prayerTimes/PrayerTimes.jsx";
import "./Home.scss";
import allahName from "../../assets/images/allahName.svg";
import { useTranslation } from "react-i18next";
import Button from "../../components/common/button/Button.jsx";

import logoShahaddah from "../../assets/logos/pillars/shahadah.svg";
import logoSalat from "../../assets/logos/pillars/salat.svg";
import logoZakat from "../../assets/logos/pillars/zakat.svg";
import logoSawm from "../../assets/logos/pillars/sawm.svg";
import logoHajj from "../../assets/logos/pillars/hajj.svg";
import RecitationQuran from "../../components/specific/recitationQuran/RecitationQuran.jsx";
import { fetchListSurah } from "../../features/quran/quranThunk.ts";
import Footer from "../../components/layout/Footer.jsx";
import videoMosque from "../../assets/videos/mosque.mp4";
import { SERVICES_LIST } from "../../constants/services.constants.ts";
import moment from "moment";
import prayerMobileImage from "../../assets/images/mobile-app/prayer.png";
import mosqueMobileImage from "../../assets/images/mobile-app/mosque.png";
import quranMobileImage from "../../assets/images/mobile-app/quran.png";
import { selectSurahReducer } from "../../features/quran/quranSlice.ts";
import Dialog from "../../components/common/dialog/Dialog.tsx";
import { openDialog } from "../../features/general/generalSlice.ts";
import RightArrow from "../../assets/logos/RightArrow.png";
import ArrowLeft from "../../assets/logos/ArrowLeft.svg";

const Home = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [selectedService, setSelectedServie] = useState(null);
  const listSorah = useAppSelector((state) => state.quran.listSorah);
  const position = useAppSelector(
    (state) => state.localisationReducer.position
  );
  const positionError = useAppSelector(
    (state) => state.localisationReducer.error
  );
  const dialog = useAppSelector((state) => state.generalSlice);

  useEffect(() => {
    dispatch(fetchListSurah());
  }, []);

  useEffect(() => {
    if (position) {
      dispatch(
        fetchPrayerTimes({ position, date: moment().format("DD-MM-YYYY") })
      );
    }
  }, [position]);

  useEffect(() => {
    if (positionError) {
      dispatch(
        openDialog({
          nameDialog: "errorLocalisation",
          title: "alert.ADD_ACCESS",
          description: "alert.POSITION_REQUIRED",
        })
      );
    }
  }, [positionError]);

  useEffect(() => {
    if (listSorah?.length) {
      dispatch(selectSurahReducer({ numSorah: 1 }));
    }
  }, [listSorah]);

  return dialog.dialogOpened && dialog.nameDialog === "errorLocalisation" ? (
    <Dialog secondaryButton={{ title: t("common.OK") }}></Dialog>
  ) : (
    <div className="home">
      <section className="section-1">
        <div className="allah-name">
          <img src={allahName} alt="" />
          <h1>{t("common.ISLAM_PATH")}</h1>
        </div>
        <div className="content">
          <video autoPlay muted loop id="video-home">
            <source src={videoMosque} type="video/mp4" />
            {/* <source src="mov_bbb.ogg" type="video/ogg" /> */}
            Your browser does not support HTML video.
          </video>
        </div>
      </section>
      {/* prayer timaes section */}
      <section>
        <PrayerTimes />
      </section>
      {/* about us section */}
      <div className="home-2">
        <section className="section-3 flex flex-col items-center">
          <h1>{t("about.WELCOME")}</h1>
          <p>{t("about.DESCRIPTION_HOME")}</p>
          <Button from="home">{t("about.DISCOVER")}</Button>
        </section>
        {/* pillers islam section */}
        <section className="section-5 flex flex-col items-center">
          <h1 style={{ marginBottom: "35px" }}>{t("pillars.TITLE")}</h1>
          <div className="pillers">
            <div className="flex flex-col items-center gap-2 sm:gap-3 md:gap-7 lg:gap-14">
              <div className="piller">
                <div className="piller-container">
                  <img src={logoShahaddah} alt="" />
                </div>
              </div>
              <h2 className="piller-name">{t("pillars.shahada")}</h2>
            </div>

            <div className="flex flex-col items-center gap-2 sm:gap-3 md:gap-7 lg:gap-14">
              <div className="piller">
                <div className="piller-container">
                  <img src={logoSalat} alt="" />
                </div>
              </div>
              <h2 className="piller-name">{t("pillars.salah")}</h2>
            </div>

            <div className="flex flex-col items-center gap-2 sm:gap-3 md:gap-7 lg:gap-14">
              <div className="piller">
                <div className="piller-container">
                  <img src={logoZakat} alt="" />
                </div>
              </div>
              <h2 className="piller-name">{t("pillars.zakat")}</h2>
            </div>

            <div className="flex flex-col items-center gap-2 sm:gap-3 md:gap-7 lg:gap-14">
              <div className="piller">
                <div className="piller-container">
                  <img src={logoSawm} alt="" />
                </div>
              </div>
              <h2 className="piller-name">{t("pillars.sawm")}</h2>
            </div>

            <div className="flex flex-col items-center gap-2 sm:gap-3 md:gap-7 lg:gap-14">
              <div className="piller">
                <div className="piller-container">
                  <img src={logoHajj} alt="" />
                </div>
              </div>
              <h2 className="piller-name">{t("pillars.hajj")}</h2>
            </div>
          </div>
        </section>
        {/* our services section */}
        <section id="services" className="section-4 flex flex-col items-center">
          <h1>{t("links.SERVICES")}</h1>
          <div className="flex gap-8 flex-wrap justify-center">
            {SERVICES_LIST.map((service, index) => (
              <CardHome
                key={index}
                service={service}
                index={index}
                selectedService={selectedService}
                setSelectedServie={setSelectedServie}
              ></CardHome>
            ))}
          </div>
        </section>

        {/* recitation quran section */}
        <section className="section-6">
          <div
            className="ml-4 md:ml-10 recitation-container"
            style={{
              alignItems: i18n?.language === "ar" ? "center" : "self-start",
            }}
          >
            <div className="">
              <div
                className="col-span-2 "
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0.7em 0",
                  flexDirection: i18n.language === "ar" ? "row-reverse" : "row",
                }}
              >
                <h1 style={{ margin: "0.7em 0", display: "inline" }}>
                  {t("quran.TITLE")}{" "}
                </h1>

                <img
                  src={i18n.language === "ar" ? ArrowLeft : RightArrow}
                  style={{
                    cursor: "pointer",
                    width: "2.65em",
                    height: "2.5em",
                    margin:
                      i18n.language === "ar" ? "0 0.5em 0 0" : "0 0 0 0.5em",
                    position: "relative",
                    top: i18n.language === "ar" ? "0.3em" : "0.4em",
                  }}
                  onClick={() => {
                    window.location.href = "/quran";
                  }}
                />
              </div>
            </div>
            <div className="section-recitation">
              <RecitationQuran from="homePage"></RecitationQuran>
            </div>
          </div>
        </section>

        {/* application mobile section */}
        <section className="section-8">
          <MobileApplicationSection />
        </section>

        {/* events section */}
        {/* <section className="section-9">
          <h1 style={{ marginBottom: "35px" }}>{t("events.TITLE")}</h1>
        </section> */}
        <Footer />
      </div>
    </div>
  );
};

const CardHome = ({ service, index, selectedService, setSelectedServie }) => {
  const { t } = useTranslation();

  const handleCardClick = () => {
    setSelectedServie(index);

    if (index === 0) {
      window.open("https://sila.mslmcommunity.com/", "_blank");
    }
  };
  return (
    <div
      className={
        selectedService === index
          ? "card-service selectedService"
          : " card-service unselectedService"
      }
      onMouseEnter={() => {
        setSelectedServie(index);
      }}
      onMouseLeave={(e) => {
        setSelectedServie(null);
      }}
      onClick={handleCardClick}
    >
      <img
        src={selectedService === index ? service.whiteImage : service.image}
        alt="logo"
      />
      <h2>{t("services." + service.title)}</h2>
      <p>{t("services." + service.description)}</p>
      <span>{t("services." + service.more)}</span>
    </div>
  );
};

const MobileApplicationSection = () => {
  const { t } = useTranslation();

  const listCards = [
    {
      title: t("PRIERE.PRAYER_REALTIME.TITLE"),
      image: prayerMobileImage,
      description: t("PRIERE.PRAYER_REALTIME.DESCRIPTION"),
    },
    {
      title: t("PRIERE.NEAREST_MOSQUES.TITLE"),
      image: mosqueMobileImage,
      description: t("PRIERE.NEAREST_MOSQUES.DESCRIPTION"),
    },
    {
      title: t("PRIERE.READ_QURAN.TITLE"),
      image: quranMobileImage,
      description: t("PRIERE.READ_QURAN.DESCRIPTION"),
    },
  ];
  return (
    <div className="flex flex-col justify-center">
      {listCards.map((card, index) => (
        <div
          className={
            "mobile-card " + (index === 1 ? "flex-row" : "flex-row-reverse")
          }
          key={index}
        >
          <img src={card.image} alt="prayer times" />
          <div className="mobile-card-details">
            <h1>{card.title}</h1>
            <p>{card.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Home;
