import React from "react";
import "./PolitiqueCookiesPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Footer from "../../components/layout/Footer.jsx";

const CookiePolicy = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={i18n.language === "ar" ? "icon-ar icon" : "icon"}
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">{t("cookiePolicy.TITLE")}</span>
      </div>

      <div className="cookiePolicy-body">
        <p>{t("cookiePolicy.introduction")}</p>

        <h2>{t("cookiePolicy.section_1.title")}</h2>
        <p>{t("cookiePolicy.section_1.Definition")}</p>
        <p>{t("cookiePolicy.section_1.Info")}</p>

        <h2>{t("cookiePolicy.section_2.title")}</h2>
        <ul>
          <p>{t("cookiePolicy.section_2.text")}</p>
          <h3>
            <b>{t("cookiePolicy.section_2.Subtitle_1.title")}</b>
          </h3>
          <li>{t("cookiePolicy.section_2.Subtitle_1.Description_1")}</li>
          <li>{t("cookiePolicy.section_2.Subtitle_1.Description_2")}</li>

          <h3>
            <b>{t("cookiePolicy.section_2.Subtitle_2.title")}</b>
          </h3>
          <li>{t("cookiePolicy.section_2.Subtitle_2.Description_1")}</li>
          <li>{t("cookiePolicy.section_2.Subtitle_2.Description_2")}</li>

          <h3>
            <b>{t("cookiePolicy.section_2.Subtitle_3.title")}</b>
          </h3>
          <li>{t("cookiePolicy.section_2.Subtitle_3.Description_1")}</li>
          <li>{t("cookiePolicy.section_2.Subtitle_3.Description_2")}</li>

          <h3>
            <b>{t("cookiePolicy.section_2.Subtitle_4.title")}</b>
          </h3>
          <li>{t("cookiePolicy.section_2.Subtitle_4.Description_1")}</li>
          <li>{t("cookiePolicy.section_2.Subtitle_4.Description_2")}</li>
        </ul>

        <h2 className="cookie-policy-subtitle">
          {t("cookiePolicy.section_3.title")}
        </h2>
        <ul>
          <p>{t("cookiePolicy.section_3.text")}</p>
          <li>{t("cookiePolicy.section_3.utilise_1")}</li>
          <li>{t("cookiePolicy.section_3.utilise_2")}</li>
          <li>{t("cookiePolicy.section_3.utilise_3")}</li>
          <li>{t("cookiePolicy.section_3.utilise_4")}</li>
        </ul>

        <section className="cookie-policy-section">
          <h2 className="cookie-policy-subtitle">
            {t("cookiePolicy.section_4.title")}
          </h2>
          <p>{t("cookiePolicy.section_4.text")}</p>
          <ul>
            <h3>
              <b>{t("cookiePolicy.section_4.Subtitle_1")}</b>
            </h3>
            <li>{t("cookiePolicy.section_4.DESCRIPTION_1")}</li>
            <h3>
              <b>{t("cookiePolicy.section_4.Subtitle_2")}</b>
            </h3>
            <li>{t("cookiePolicy.section_4.DESCRIPTION_2")}</li>
          </ul>
        </section>

        <h2>{t("cookiePolicy.section_5.title")}</h2>
        <ul>
          <li>
            <strong>{t("cookiePolicy.section_5.sessionCookies")}</strong>:{" "}
            {t("cookiePolicy.section_5.DescriptionSession")}
          </li>
          <li>
            <strong>{t("cookiePolicy.section_5.persistentCookies")}</strong>:{" "}
            {t("cookiePolicy.section_5.DescriptionPersistent")}
          </li>
        </ul>

        <h2>{t("cookiePolicy.section_6.title")}</h2>
        <p>{t("cookiePolicy.section_6.Description")}</p>
        <p>{t("cookiePolicy.section_6.text")}</p>

        <h2>{t("cookiePolicy.section_7.title")}</h2>
        <p>{t("cookiePolicy.section_7.contactDescription")}</p>
        <p>
          <li>
            <strong>{t("cookiePolicy.section_7.email")}</strong> :
            <Link to="/Contact"> contact@mslmcommunity.com .</Link>
          </li>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default CookiePolicy;
