import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./StoryPage.scss";
import { useNavigate, useParams } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Footer from "../../components/layout/Footer.jsx";
import { Helmet } from "react-helmet";

const StoryPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { nameProphet } = useParams();

  return (
    <>
      <Helmet>
        <title>
          {t(`prophet_story.TITLE`) + t(`prophet_story.${nameProphet}.NAME`)} |
          Muslim community
        </title>
      </Helmet>
      <div className="pages-position">
        <div className="pages-header">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={i18n.language === "ar" ? "icon-ar icon" : "icon"}
            onClick={() => {
              navigate(-1);
            }}
          />
          <span className="title-page">
            {t(`prophet_story.TITLE`) + t(`prophet_story.${nameProphet}.NAME`)}
          </span>
        </div>
        <div className="story-body">
          <div
            dangerouslySetInnerHTML={{
              __html: t(`prophet_story.${nameProphet}.HISTORY`),
            }}
          />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default StoryPage;
