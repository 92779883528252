const ROUTES_CONSTANTS = {
  home: {
    route: "home",
    routeWithSlash: "/",
    title: `links.HOME`,
  },
  Services: {
    route: "services",
    routeWithSlash: "/services",
    title: `links.Services`,
  },
  Sila: {
    route: "sila",
    routeWithSlash: "/sila",
    title: `links.Sila`,
  },
  Marketplace: {
    route: "marketplace",
    routeWithSlash: "/marketplace",
    title: `links.Marketplace`,
  },
  Ibadat: {
    route: "Ibadat",
    routeWithSlash: "/Ibadat",
    title: `links.Ibadat`,
  },

  quranPage: {
    route: "quran/*",
    routeWithSlash: "/quran",
    title: `links.QURAN`,
  },

  mosquePage: {
    route: "mosque",
    routeWithSlash: "/mosque",
    title: `links.MOSQUE`,
  },

  Adhkar: {
    route: "Adhkar",
    routeWithSlash: "/Adhkar/*",
    title: `links.Adhkar`,
  },

  dhikrPage: {
    route: "dhikr/*",
    routeWithSlash: "/dhikr",
    title: `links.DHIKR`,
  },

  learnPage: {
    route: "learn/*",
    routeWithSlash: "/learn",
  },

  WuduGhousl: {
    route: "wudhu&ghousl",
    routeWithSlash: "/",
    title: `TitleTab.WuduGhousl`,
  },

  Wudhu: {
    route: "wudhu",
    routeWithSlash: "/wudhu/*",
    title: `TitleTab.Wudhu`,
  },

  Ghousl: {
    route: "ghousl",
    routeWithSlash: "/ghousl/*",
    title: `TitleTab.Ghousl`,
  },

  ElSalat: {
    route: "salat",
    routeWithSlash: "/",
    title: `TitleTab.ELSalat`,
  },

  stepsPrayer: {
    route: "steps",
    routeWithSlash: ":prayer/steps",
    title: `TitleTab.stepsPrayers`,
  },

  prayerTimesPage: {
    route: "prayer-times",
    routeWithSlash: "/prayer-times",
    title: `links.SALAT`,
  },

  zakatPage: {
    route: "zakat-calculator",
    routeWithSlash: "/zakat-calculator",
    title: `links.ZAKAT`,
  },

  aboutPage: {
    route: "about",
    routeWithSlash: "/about",
    title: `links.ABOUT`,
  },

  contactPage: {
    route: "contact",
    routeWithSlash: "/contact",
    title: `links.CONTACT`,
  },

  NamesAllah: {
    route: "names-of-allah",
    routeWithSlash: "/names-of-allah",
    title: `links.ALLAH_NAMES`,
  },

  Prophets: {
    route: "prophets-stories",
    routeWithSlash: "/prophets-stories/*",
    title: `links.PROPHETS_STORIES`,
  },

  // Sheikh: {
  //   route: "list-sheikh",
  //   routeWithSlash: "/list-sheikh",
  //   title: `links.SHEIKHS`,
  // },

  Hadiths: {
    route: "hadiths-prophet/*",
    routeWithSlash: "/hadiths-prophet",
    title: `links.AHADITH`,
  },

  conditionsPage: {
    route: "conditions_generales",
    routeWithSlash: "/conditions_generales",
    title: `TitleTab.conditionsPage`,
  },

  privacyPolicyPage: {
    route: "politique_confidentialite",
    routeWithSlash: "/politique_confidentialite",
    title: `TitleTab.PrivacyPolicy`,
  },

  quizPage: {
    route: "quiz-islamique",
    routeWithSlash: "/quiz-islamique",
    title: `links.QUIZ`,
  },

  cookiesPolicy: {
    route: "cookies_politique",
    routeWithSlash: "/cookies",
    title: `FOOTER.LEGAL.COOKIES`,
  },
};

export default ROUTES_CONSTANTS;
