import { useTranslation } from "react-i18next";
import { Language } from "../../enums/Language.ts";
import { useEffect, useState } from "react";
import "./NavBar.scss";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/logos/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import ROUTES_CONSTANTS from "../../constants/routes.constants.ts";

const NavBar = () => {
  const location = useLocation();
  const [pathName, setPathName] = useState("/");
  const [navOpened, setNavOpened] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  const toggleNavBar = () => {
    setNavOpened(!navOpened);
  };
  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  useEffect(() => {
    const tham = document.querySelector(".tham");
    tham &&
      tham.addEventListener("click", (_ev) => {
        tham.classList.toggle("tham-active");
      });
  }, []);

  const closeNavBar = () => {
    const tham = document.querySelector(".tham");
    tham.classList.remove("tham-active");
    setNavOpened(false);
  };

  const changeLanguage = (language) => {
    if (language) {
      setLang(language);
      i18n.changeLanguage(language);
    }
  };

  const Links = () => {
    return (
      <ul className="flex links-nav">
        <li className="m-1" onClick={closeNavBar}>
          <NavLink to={ROUTES_CONSTANTS.home.routeWithSlash}>
            {t(ROUTES_CONSTANTS.home.title)}
          </NavLink>
        </li>
        {/* Services */}
        <li className="m-1 resources-link">
          <span
            className={
              (i18n.language === "ar" ? "arabic-link " : "") +
              (pathName &&
                (pathName.startsWith(
                  ROUTES_CONSTANTS.Marketplace.routeWithSlash
                ) || pathName.startsWith(ROUTES_CONSTANTS.Sila.routeWithSlash)
                  ? "has-dropdown active"
                  : "has-dropdown"))
            }
          >
            {t("links.SERVICES")}
            <FontAwesomeIcon icon={faAngleDown} className="icon forward" />
          </span>
          <ServicesSelectList closeNavBar={closeNavBar} />
        </li>
        <li className="m-1">
          <NavLink
            to={ROUTES_CONSTANTS.mosquePage.routeWithSlash}
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.mosquePage.title)}
          </NavLink>
        </li>
        {/* Ibadat */}
        <li className="m-1 resources-link">
          <span
            className={
              (i18n.language === "ar" ? "arabic-link " : "") +
              (pathName &&
                (pathName.startsWith(
                  ROUTES_CONSTANTS.prayerTimesPage.routeWithSlash
                ) ||
                pathName.startsWith(
                  ROUTES_CONSTANTS.zakatPage.routeWithSlash
                ) ||
                pathName.startsWith(ROUTES_CONSTANTS.quranPage.routeWithSlash)
                  ? "has-dropdown active"
                  : "has-dropdown"))
            }
          >
            {t("links.Ibadat")}
            <FontAwesomeIcon icon={faAngleDown} className="icon forward" />
          </span>
          <IbadatSelectList closeNavBar={closeNavBar} />
        </li>
        {/* Adhkaar */}
        <li className="m-1 resources-link">
          <span
            className={
              (i18n.language === "ar" ? "arabic-link " : "") +
              (pathName &&
              (pathName.startsWith(ROUTES_CONSTANTS.dhikrPage.routeWithSlash) ||
                pathName.startsWith(ROUTES_CONSTANTS.Hadiths.routeWithSlash) ||
                pathName.startsWith(ROUTES_CONSTANTS.NamesAllah.routeWithSlash))
                ? "has-dropdown active"
                : "has-dropdown")
            }
          >
            {t("links.Adhkar")}
            <FontAwesomeIcon icon={faAngleDown} className="icon forward" />
          </span>
          <AdhkarSelectList closeNavBar={closeNavBar} />
        </li>

        {/* Learning */}
        <li className="m-1 apprendre-link">
          <span
            className={
              (i18n.language === "ar" ? "arabic-link " : "") +
              (pathName &&
              pathName.startsWith(ROUTES_CONSTANTS.learnPage.routeWithSlash)
                ? "has-dropdown active"
                : "has-dropdown")
            }
          >
            {t("links.LEARN")}
            <FontAwesomeIcon icon={faAngleDown} className="icon forward" />
          </span>
          <LearnSelectList closeNavBar={closeNavBar}></LearnSelectList>
        </li>
        <li className="m-1">
          <NavLink
            to={ROUTES_CONSTANTS.aboutPage.routeWithSlash}
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.aboutPage.title)}
          </NavLink>
        </li>
        <li className="m-1">
          <NavLink
            to={ROUTES_CONSTANTS.contactPage.routeWithSlash}
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.contactPage.title)}
          </NavLink>
        </li>
        <li className="m-1 language-link">
          <span
            className={
              "has-dropdown " + (i18n.language === "ar" ? "arabic-link " : "")
            }
          >
            {t(`common.${lang.toUpperCase()}`)}
            <FontAwesomeIcon icon={faAngleDown} className="icon forward" />
          </span>

          <div
            className={`drop-down-section ${
              i18n.language === "ar" ? "dropdown-rtl" : ""
            }`}
          >
            <ul style={{ top: "0.8rem", padding: "0 0 0.5rem 0" }}>
              {lang !== Language.FR && (
                <li onClick={() => changeLanguage(Language.FR)}>
                  <span> {t("common.FR")}</span>
                </li>
              )}
              {lang !== Language.EN && (
                <li onClick={() => changeLanguage(Language.EN)}>
                  <span> {t("common.EN")}</span>
                </li>
              )}
              {lang !== Language.AR && (
                <li onClick={() => changeLanguage(Language.AR)}>
                  <span> {t("common.AR")}</span>
                </li>
              )}
            </ul>
          </div>
        </li>
      </ul>
    );
  };

  return (
    <nav
      className={
        "nav-bar p-2 " +
        (pathName === ROUTES_CONSTANTS.home.routeWithSlash
          ? "home "
          : "not-home ") +
        (i18n.language === "ar" ? "nav-bar-ar" : "")
      }
    >
      <div
        className="flex items-center"
        onClick={() => navigate(ROUTES_CONSTANTS.home.routeWithSlash)}
        style={{ cursor: "pointer" }}
      >
        <img src={logo} className=""></img>
        <span className="app-name">Muslim community </span>
      </div>
      {/* large screen */}
      <div className="nav-large-screen">
        <Links></Links>
      </div>

      {/* small screen */}
      <div className="nav-small-screen">
        <div className="tham tham-e-squeeze tham-w-6" onClick={toggleNavBar}>
          <div className="tham-box">
            <div
              className={
                "tham-inner " +
                (pathName === ROUTES_CONSTANTS.home.routeWithSlash
                  ? "bg-white"
                  : "")
              }
            />
          </div>
        </div>
        {navOpened && (
          <div
            className={
              "nav-small-screen-opened " +
              (i18n.language === "ar" ? "arabic-nav" : "")
            }
          >
            <Links></Links>
          </div>
        )}
      </div>
    </nav>
  );
};

const LearnSelectList = ({ closeNavBar }) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`drop-down-section ${
        i18n.language === "ar" ? "dropdown-rtl dropdown-right" : ""
      }`}
    >
      {" "}
      <ul>
        <li className="">
          <NavLink
            to={`${ROUTES_CONSTANTS.learnPage.routeWithSlash}/${ROUTES_CONSTANTS.WuduGhousl.route}`}
            onClick={closeNavBar}
          >
            {t("learn.WUDHU_GHOUSL.TITLE")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`${ROUTES_CONSTANTS.learnPage.routeWithSlash}/${ROUTES_CONSTANTS.ElSalat.route}`}
            onClick={closeNavBar}
          >
            {t("learn.SALAT.TITLE")}
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`${ROUTES_CONSTANTS.learnPage.routeWithSlash}/${ROUTES_CONSTANTS.Prophets.route}`}
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.Prophets.title)}
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`${ROUTES_CONSTANTS.learnPage.routeWithSlash}/${ROUTES_CONSTANTS.quizPage.route}`}
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.quizPage.title)}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

const ServicesSelectList = ({ closeNavBar }) => {
  const { t, i18n } = useTranslation();
  const isMarketplace = true;
  return (
    <div
      className={`drop-down-section ${
        i18n.language === "ar" ? "dropdown-rtl dropdown-right" : ""
      }`}
    >
      <ul>
        <li className="m-1">
          <a
            href="https://sila.mslmcommunity.com/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.Sila.title)}
          </a>
        </li>
        <li className={`m-1 ${isMarketplace ? "disabled" : ""}`}>
          {isMarketplace ? (
            <span>{t(ROUTES_CONSTANTS.Marketplace.title)}</span>
          ) : (
            <NavLink
              to={`/${ROUTES_CONSTANTS.Services.route}/${ROUTES_CONSTANTS.Marketplace.route}`}
              onClick={closeNavBar}
            >
              {t(ROUTES_CONSTANTS.Marketplace.title)}
            </NavLink>
          )}
        </li>
      </ul>
    </div>
  );
};

const IbadatSelectList = ({ closeNavBar }) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`drop-down-section ${
        i18n.language === "ar" ? "dropdown-rtl dropdown-right" : ""
      }`}
    >
      <ul>
        <li className="m-1">
          <NavLink
            to={ROUTES_CONSTANTS.prayerTimesPage.routeWithSlash}
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.prayerTimesPage.title)}
          </NavLink>
        </li>
        <li className="m-1">
          <NavLink
            to={ROUTES_CONSTANTS.zakatPage.routeWithSlash}
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.zakatPage.title)}
          </NavLink>
        </li>
        <li className="m-1">
          <NavLink
            to={ROUTES_CONSTANTS.quranPage.routeWithSlash}
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.quranPage.title)}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

const AdhkarSelectList = ({ closeNavBar }) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`drop-down-section ${
        i18n.language === "ar" ? "dropdown-rtl dropdown-right" : ""
      }`}
    >
      <ul>
        <li className="m-1">
          <NavLink
            to={ROUTES_CONSTANTS.dhikrPage.routeWithSlash}
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.dhikrPage.title)}
          </NavLink>
        </li>
        <li className="m-1">
          <NavLink
            to={ROUTES_CONSTANTS.Hadiths.routeWithSlash}
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.Hadiths.title)}
          </NavLink>
        </li>
        <li className="m-1">
          <NavLink
            to={ROUTES_CONSTANTS.NamesAllah.routeWithSlash}
            onClick={closeNavBar}
          >
            {t(ROUTES_CONSTANTS.NamesAllah.title)}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default NavBar;
