import { useNavigate } from "react-router-dom";
import "./CardDhikr.scss";
import { useTranslation } from "react-i18next";
import ROUTES_CONSTANTS from "../../../constants/routes.constants.ts";

const CardDhikr = ({ image, title }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className="card-dhikr"
      onClick={() =>
        navigate(`${ROUTES_CONSTANTS.dhikrPage.routeWithSlash}/${title}`)
      }
    >
      <div className="image-container">
        <img className="image-dhikr" src={image} alt={title} />
      </div>
      <div className="title-container">{t(`adhkar.TITLES.${title}`)}</div>
    </div>
  );
};

export default CardDhikr;
