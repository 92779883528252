import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DetailsHadithPage.scss";
import React from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ROUTES_CONSTANTS from "../../constants/routes.constants.ts";
import { Helmet } from "react-helmet";

const DetailsHadithPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { hadith } = useParams();

  return (
    <div className="pages-position">
      <div className="pages-header">
        <Helmet>
          <title>{hadith} | Muslim community</title>
        </Helmet>
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={i18n.language === "ar" ? "icon-ar icon" : "icon"}
          onClick={() => {
            navigate(ROUTES_CONSTANTS.Hadiths.routeWithSlash);
          }}
        />
        <span className="title-page">{hadith}</span>
      </div>
      <div className="hadith-body">
        <iframe
          allowfullscreen="allowfullscreen"
          scrolling="no"
          className="fp-iframe"
          src={location.state?.url}
          style={{
            border: "1px solid lightgray",
            width: "100%",
            height: "calc(100vh - 15em - -72px)",
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default DetailsHadithPage;
