import "./ContactUsPage.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { addContact } from "../../services/common.service.ts";
import Footer from "../../components/layout/Footer.jsx";
import { patternPhone } from "../../constants/patterns_regex.constants.ts";
import { useForm } from "react-hook-form";
import ROUTES_CONSTANTS from "../../constants/routes.constants.ts";
import { useState } from "react";

const ContactUsPage = () => {
  const [toastId, setToastId] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleAddContact = (data) => {
    if (toastId) return;

    setToastId(true);

    toast.dismiss();

    toast.promise(addContact(data), {
      loading: t("alertSaving.saving"),
      success: () => {
        navigate(ROUTES_CONSTANTS.home.routeWithSlash);
        return <b>{t("alert.MESSAGE_SENT")}</b>;
      },
      error: (err) => {
        setToastId(false);
        return <b>{t("alert.ECHEC_SEND")}</b>;
      },
    });
  };

  return (
    <div className="pages-position">
      <div className="pages-header">
        <span className="title-page">{t("contact.TITLE")}</span>
      </div>
      <div className="contact-body">
        <form onSubmit={handleSubmit((data) => handleAddContact(data))}>
          <div className="grid gap-y-2 gap-x-6 mb-6 md:grid-cols-2">
            <div>
              <label
                htmlFor="prenom"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {t("contact.FORM.prenom")}
              </label>
              <input
                type="text"
                id="prenom"
                name="prenom"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-green block w-full p-2.5"
                placeholder="John"
                defaultValue=""
                {...register("prenom", {
                  required: t("textError.required"),
                  minLength: { value: 3, message: t("textError.minLength") },
                  maxLength: { value: 20, message: t("textError.maxLength") },
                })}
              />
              <p className="text-sm text-redError h-6">
                <span className="font-medium">{errors.prenom?.message}</span>
              </p>
            </div>
            <div>
              <label
                htmlFor="nom"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {t("contact.FORM.nom")}
              </label>
              <input
                type="text"
                id="nom"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-green block w-full p-2.5"
                placeholder="Doe"
                {...register("nom", {
                  required: t("textError.required"),
                  minLength: { value: 3, message: t("textError.minLength") },
                  maxLength: { value: 20, message: t("textError.maxLength") },
                })}
              />
              <p className="text-sm text-redError h-6 ">
                <span className="font-medium">{errors.nom?.message}</span>
              </p>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {t("contact.FORM.email")}
              </label>
              <input
                type="email"
                id="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-green block w-full p-2.5"
                placeholder="john.doe@company.com"
                {...register("email", { required: t("textError.required") })}
              />
              <p className="text-sm text-redError h-6 ">
                <span className="font-medium">{errors.email?.message}</span>
              </p>
            </div>
            <div>
              <label
                htmlFor="numTel"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {t("contact.FORM.numTel")}
              </label>
              <input
                type=""
                id="numTel"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-green block w-full p-2.5"
                placeholder={t("contact.FORM.numTel")}
                {...register("numTel", {
                  required: t("textError.required"),
                  pattern: {
                    value: patternPhone,
                    message: t("textError.pattern"),
                  },
                })}
              />
              <p className="text-sm text-redError h-6 ">
                <span className="font-medium">{errors.numTel?.message}</span>
              </p>
            </div>
            <div>
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {t("contact.FORM.description")}
              </label>
              <textarea
                id="description"
                rows="4"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none focus:border-green"
                placeholder={t("placeholder.MESSAGE")}
                {...register("description", {
                  required: t("textError.required"),
                  minLength: { value: 3, message: t("textError.minLength") },
                })}
              ></textarea>
              <p className="text-sm text-redError h-6">
                <span className="font-medium">
                  {errors.description?.message}
                </span>
              </p>
            </div>
          </div>

          <button
            type="submit"
            className="text-white bg-green hover:bg-blackGreen focus:outline-none focus:border-green font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            {t("contact.FORM.submit")}
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUsPage;
