import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages/home/Home";
import Error404 from "./pages/error404/Error404";
import ErrorPage from "./pages/errorPage/ErrorPage";
import NavBar from "./components/layout/NavBar";
import QuranPage from "./pages/quranPage/QuranPage";
import MosquePage from "./pages/mosquePage/MosquePage";
import SurahPage from "./pages/surahPage/SurahPage";
import ListAdhkarPage from "./pages/listAdhkarPage/ListAdhkarPage";
import { APIProvider } from "@vis.gl/react-google-maps";
import ConditionsGenerales from "./pages/conditionsGenerales/ConditionsGenerales";
import AboutUsPage from "./pages/aboutUsPage/AboutUsPage";
import DetailDhikrPage from "./pages/detailDhikrPage/DetailDhikrPage";
import PolitiqueConfidentialite from "./pages/politiqueConfidentialite/PolitiqueConfidentialite";
import ContactUsPage from "./pages/contactUsPage/ContactUsPage";
import { Toaster } from "react-hot-toast";
import ZakatPage from "./pages/zakatPage/ZakatPage";
import NamesOfAllah from "./pages/namesOfAllah/NamesOfAllah";
import PrayerTimesPage from "./pages/prayerTimesPage/PrayerTimesPage.tsx";
import ProphetsStoriesPage from "./pages/prophetsStoriesPage/ProphetsStoriesPage";
import StoryPage from "./pages/storyPage/StoryPage";

import Chatbott from "./components/specific/chat_bot/Chatbott";
import SalahPage from "./pages/learnPage/SalahPage";
import RakaaPage from "./pages/learnPage/RakaaPage";
import DetailSalahPage from "./pages/learnPage/DetailSalahPage.jsx";
import HadithsPage from "./pages/hadithsPage/HadithsPage.tsx";
import DetailsHadithPage from "./pages/detailsHadithPage/DetailsHadithPage.tsx";
import GhouslSteps from "./pages/learnPage/wudhu/ghouslSteps/GhouslSteps.tsx";
import WudhuSteps from "./pages/learnPage/wudhu/wudhuSteps/WudhuSteps.tsx";
import WudhuGhouslPage from "./pages/learnPage/wudhu/WudhuGhouslPage.jsx";
import { useEffect } from "react";
import { getAndSetLocalisation } from "./services/common.service.ts";
import { useAppDispatch } from "./app/hooks.ts";
import { useTranslation } from "react-i18next";
import Quiz from "./pages/QuizPage/Quiz.jsx";
import ROUTES_CONSTANTS from "./constants/routes.constants.ts";
import CookiePolicy from "./pages/PolitiqueCookiesPage/PolitiqueCookiesPage.jsx";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}
function App() {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getAndSetLocalisation(dispatch);
  });

  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;
  }, [i18n, i18n.language]);

  return (
    <APIProvider
      apiKey={"AIzaSyATYxvLpHtisp-fr-1V-TjRnu7DHsAQJqc"}
      onLoad={() => console.log("Maps API has loaded.")}
      libraries={["places"]}
    >
      <Chatbott />
      <BrowserRouter>
        <ScrollToTop />
        <NavBar />
        <Routes>
          <Route
            path={ROUTES_CONSTANTS.home.routeWithSlash}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.home.title)} | Muslim community
                  </title>
                </Helmet>
                <Home />
              </>
            }
          />
          <Route
            path={ROUTES_CONSTANTS.quranPage.route}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.quranPage.title)} | Muslim community
                  </title>
                </Helmet>
                <QuranRoutes />
              </>
            }
          />
          <Route
            path={ROUTES_CONSTANTS.mosquePage.routeWithSlash}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.mosquePage.title)} | Muslim community
                  </title>
                </Helmet>
                <MosquePage />
              </>
            }
          />
          <Route
            path={ROUTES_CONSTANTS.dhikrPage.route}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.dhikrPage.title)} | Muslim community
                  </title>
                </Helmet>
                <AdhkarRoutes />
              </>
            }
          />
          <Route
            path={ROUTES_CONSTANTS.learnPage.route}
            errorElement={<ErrorPage />}
            element={<LearnRoutes t={t} />}
          />
          <Route
            path={ROUTES_CONSTANTS.prayerTimesPage.routeWithSlash}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.prayerTimesPage.title)} | Muslim
                    community
                  </title>
                </Helmet>
                <PrayerTimesPage />
              </>
            }
          />
          <Route
            path={ROUTES_CONSTANTS.zakatPage.routeWithSlash}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.zakatPage.title)} | Muslim community
                  </title>
                </Helmet>
                <ZakatPage />
              </>
            }
          />
          <Route
            path={ROUTES_CONSTANTS.NamesAllah.routeWithSlash}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.NamesAllah.title)} | Muslim community
                  </title>
                </Helmet>
                <NamesOfAllah />
              </>
            }
          />

          {/* <Route
            path={ROUTES_CONSTANTS.Sheikh.routeWithSlash}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.Sheikh.title)} | Muslim community
                  </title>
                </Helmet>
                <ListSheikhPage />
              </>
            }
          /> */}

          <Route
            path={ROUTES_CONSTANTS.aboutPage.routeWithSlash}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.aboutPage.title)} | Muslim community
                  </title>
                </Helmet>
                <AboutUsPage />
              </>
            }
          />
          <Route
            path={ROUTES_CONSTANTS.contactPage.routeWithSlash}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.contactPage.title)} | Muslim community
                  </title>
                </Helmet>
                <ContactUsPage />
              </>
            }
          />
          <Route
            path={ROUTES_CONSTANTS.conditionsPage.routeWithSlash}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.conditionsPage.title)} | Muslim
                    community
                  </title>
                </Helmet>
                <ConditionsGenerales />
              </>
            }
          />
          <Route
            path={ROUTES_CONSTANTS.privacyPolicyPage.routeWithSlash}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.privacyPolicyPage.title)} | Muslim
                    community
                  </title>
                </Helmet>
                <PolitiqueConfidentialite />
              </>
            }
          />

          <Route
            path={ROUTES_CONSTANTS.Hadiths.route}
            errorElement={<ErrorPage />}
            element={<HadithRoutes t={t} />}
          />

          <Route
            path={ROUTES_CONSTANTS.cookiesPolicy.routeWithSlash}
            errorElement={<ErrorPage />}
            element={
              <>
                <Helmet>
                  <title>
                    {t(ROUTES_CONSTANTS.cookiesPolicy.title)} | Muslim community
                  </title>
                </Helmet>
                <CookiePolicy />
              </>
            }
          />

          <Route path="*" element={<Error404 />} />
        </Routes>
        <Toaster />
      </BrowserRouter>
    </APIProvider>
  );
}

const QuranRoutes = () => (
  <Routes>
    <Route path="" element={<QuranPage />} />
    <Route path=":numSorah" element={<SurahPage />} />
  </Routes>
);

const LearnRoutes = ({ t }) => (
  <Routes>
    <Route path="salat/*" element={<SalatRoutes />} />
    <Route path="wudhu&ghousl/*" element={<WudhuRoutes />} />
    <Route
      path={ROUTES_CONSTANTS.Prophets.routeWithSlash}
      errorElement={<ErrorPage />}
      element={
        <>
          <Helmet>
            <title>
              {t(ROUTES_CONSTANTS.Prophets.title)} | Muslim community
            </title>
          </Helmet>
          <ProphetsRoutes />
        </>
      }
    />
    <Route
      path={ROUTES_CONSTANTS.quizPage.routeWithSlash}
      errorElement={<ErrorPage />}
      element={
        <>
          <Helmet>
            <title>
              {t(ROUTES_CONSTANTS.quizPage.title)} | Muslim community
            </title>
          </Helmet>
          <Quiz />
        </>
      }
    />
  </Routes>
);

const AdhkarRoutes = () => (
  <Routes>
    <Route path="" element={<ListAdhkarPage />} />
    <Route path=":titleDhikr" element={<DetailDhikrPage />} />
  </Routes>
);

const ProphetsRoutes = () => (
  <Routes>
    <Route path="" element={<ProphetsStoriesPage />} />
    <Route path=":nameProphet" element={<StoryPage />} />
  </Routes>
);

const SalatRoutes = () => {
  const { t } = useTranslation();

  return (
    <>
      <Routes>
        <Route
          path={ROUTES_CONSTANTS.ElSalat.routeWithSlash}
          element={
            <>
              <Helmet>
                <title>
                  {t(ROUTES_CONSTANTS.ElSalat.title)} | Muslim community
                </title>
              </Helmet>

              <SalahPage />
            </>
          }
        />
        <Route path=":prayer" element={<DetailSalahPage />} />
        <Route
          path={ROUTES_CONSTANTS.stepsPrayer.routeWithSlash}
          element={
            <>
              <Helmet>
                <title>
                  {t(ROUTES_CONSTANTS.stepsPrayer.title)} | Muslim community
                </title>
              </Helmet>
              <RakaaPage />{" "}
            </>
          }
        />
      </Routes>
    </>
  );
};

const WudhuRoutes = () => {
  const { t } = useTranslation();

  return (
    <>
      <Routes>
        <Route
          path={ROUTES_CONSTANTS.WuduGhousl.routeWithSlash}
          element={
            <>
              <Helmet>
                <title>
                  {t(ROUTES_CONSTANTS.WuduGhousl.title)} | Muslim community
                </title>
              </Helmet>
              <WudhuGhouslPage />
            </>
          }
        />
        <Route
          path={ROUTES_CONSTANTS.Wudhu.routeWithSlash}
          errorElement={<ErrorPage />}
          element={
            <>
              <Helmet>
                <title>
                  {t(ROUTES_CONSTANTS.Wudhu.title)} | Muslim community
                </title>
              </Helmet>
              <WudhuSteps />
            </>
          }
        />
        <Route
          path={ROUTES_CONSTANTS.Ghousl.routeWithSlash}
          errorElement={<ErrorPage />}
          element={
            <>
              <Helmet>
                <title>
                  {t(ROUTES_CONSTANTS.Ghousl.title)} | Muslim community
                </title>
              </Helmet>
              <GhouslSteps />
            </>
          }
        />
      </Routes>
    </>
  );
};

const HadithRoutes = ({ t }) => (
  <Routes>
    <Route
      path="/"
      element={
        <>
          <Helmet>
            <title>
              {t(ROUTES_CONSTANTS.Hadiths.title)} | Muslim community
            </title>
          </Helmet>
          <HadithsPage />
        </>
      }
    />

    <Route path=":hadith" element={<DetailsHadithPage />} />
  </Routes>
);

export default App;
