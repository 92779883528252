import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import {
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useNavigate } from "react-router-dom";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logos/logo.svg";
import logoAppStore from "../../assets/icons/app-store.svg";
import logoGooglePlay from "../../assets/icons/google-play.svg";
import { useEffect, useState } from "react";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  const SectionDescription = () => {
    return (
      <div className="section-description">
        <div
          className="flex items-center"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        >
          <img
            src={logo}
            className={i18n.language === "ar" ? "logo-ar" : "logo"}
          ></img>
          <span className="app-name">{t("FOOTER.PAGES.TITLE")}</span>
        </div>
        <div className="">
          <span className="description-community">
            {t("FOOTER.COMMUNITY_DESCRIPTION.TEXT")}
          </span>
        </div>
        {screenWidth < 900 && <hr className="mt-4" />}
      </div>
    );
  };
  const SectionPagesLinks = () => {
    return (
      <div className="">
        <div className="font-bold mb-6">
          <span>{t("FOOTER.LINKS.PAGES")}</span>
        </div>
        <ul className="flex flex-col gap-2 lg:gap-4">
          <li className="mb-2 font-bold">
            <NavLink to="/about">{t("FOOTER.LINKS.ABOUT")}</NavLink>
          </li>
          <li className="mb-2 font-bold">
            <NavLink to="/contact">{t("FOOTER.LINKS.CONTACT")}</NavLink>
          </li>
          <li className="mb-2 font-bold">
            <a href="/#services">{t("FOOTER.LINKS.SERVICES")}</a>
          </li>
        </ul>
      </div>
    );
  };
  const SectionContactLinks = () => {
    return (
      <div className="">
        <div className="font-bold mb-6">
          <span>{t("FOOTER.CONTACT.TITLE")}</span>
        </div>
        <div className="flex flex-col gap-3 lg:gap-6 mt-4">
          <div className="flex gap-3 items-center">
            <FontAwesomeIcon icon={faPhone} className="icon icon-footer" />
            <span className="font-bold">{t("FOOTER.CONTACT.PHONE")}</span>
          </div>
          <div className="flex gap-3 items-center">
            <FontAwesomeIcon icon={faEnvelope} className="icon icon-footer" />
            <span className="font-bold">{t("FOOTER.CONTACT.EMAIL")}</span>
          </div>
          <div className="flex gap-4 items-center">
            <FontAwesomeIcon
              icon={faLocationDot}
              className="icon icon-footer"
            />
            <div className="flex flex-col">
              <span className="font-bold">
                {t("FOOTER.CONTACT.ADRESS.LINE_1")}
              </span>
              <span className="font-bold">
                {t("FOOTER.CONTACT.ADRESS.LINE_2")}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SectionDownloadMobile = () => {
    return (
      <div className="section-mobile">
        <div className="mobile-applications">
          <h2 className="mb-4">{t("FOOTER.MOBILE_APP.DOWNLOAD")}</h2>
          <div className="flex gap-1 lg:gap-4">
            <a
              href="https://apps.apple.com/fr/app/muslim-community/id6557063908"
              target="_blank"
            >
              <img
                src={logoAppStore}
                alt="app store"
                className="cursor-pointer"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.muslimcommunity&hl=en_US"
              target="_blank"
            >
              <img
                src={logoGooglePlay}
                alt="google play"
                className="cursor-pointer"
              />
            </a>
          </div>
        </div>
        {screenWidth < 900 && <hr className="mt-4" />}
      </div>
    );
  };

  const LinksSocialMedia = () => {
    return (
      <div className="flex justify-end">
        <div className="social-media">
          <FontAwesomeIcon icon={faTwitter} className="icon icon-footer" />
        </div>
        <div className="social-media">
          <FontAwesomeIcon icon={faLinkedinIn} className="icon icon-footer" />
        </div>
        <div className="social-media">
          <FontAwesomeIcon icon={faInstagram} className="icon icon-footer" />
        </div>
        <div className="social-media">
          <FontAwesomeIcon icon={faFacebookF} className="icon icon-footer" />
        </div>
      </div>
    );
  };

  return (
    <footer className="footer text-gray-900 mt-16">
      {screenWidth > 900 && (
        <div className="layout-footer">
          <SectionDescription />
          <SectionPagesLinks />
          <SectionContactLinks />
          <SectionDownloadMobile />
        </div>
      )}
      {screenWidth <= 900 && (
        <div className="layout-footer">
          <SectionDescription />
          <SectionDownloadMobile />
          <SectionPagesLinks />
          <SectionContactLinks />
        </div>
      )}

      {/* botton block */}
      <hr />
      <div className="footer-block-2 flex justify-between py-4 min-900-screen:px-4">
        <div className="">
          <ul className="flex gap-2 lg:gap-4">
            <NavLink to="/politique_confidentialite">
              {t("FOOTER.LEGAL.PRIVACY_POLICY")}
            </NavLink>
            <NavLink to="/conditions_generales">
              {t("FOOTER.LEGAL.TERMS_CONDITIONS")}
            </NavLink>
            <NavLink to="/cookies">{t("FOOTER.LEGAL.COOKIES")}</NavLink>
          </ul>
        </div>
        <div
          className="flex items-center justify-center flex-col gap-2"
          style={{ height: "fit-content" }}
        >
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCopyright} className="icon icon-footer" />
            <a href="https://www.duetodata.com/fr" target="_blank">
              DueToData
            </a>
            &nbsp;- 2024, {t("FOOTER.COPYRIGHT.TEXT")}
          </div>
          {screenWidth <= 900 && <LinksSocialMedia />}
        </div>
        {screenWidth > 900 && <LinksSocialMedia />}
      </div>
    </footer>
  );
};

export default Footer;
